const app = angular.module('editor', ['ngRoute', 'ngSanitize']);

import HomeTemplate from '../views/home.jade'
import LoginTemplate from '../views/login.jade'
import DashboardTemplate from '../views/dashboard.jade'
import AccountSettingProfileTemplate from '../views/account/setting/profile.jade'
import AccountSettingContactTemplate from '../views/account/setting/contact.jade'
import AccountMessageContactTemplate from '../views/account/message.jade'
import GameNewTemplate from '../views/game/new.jade'
import GameMultiplayerNewTemplate from '../views/game/multiplayer/new.jade'
import GameListTemplate from '../views/game/list.jade'
import GameDetailTemplate from '../views/game/detail/detail.jade'
import GameDetailDynamicTemplate from '../views/game/detail/dynamic.jade'
import GameDetailResourceTemplate from '../views/game/detail/resource/index.jade'
import GameDetailAnalysisTemplate from '../views/game/detail/analysis/index.jade'
import GameDetailPlayerTemplate from '../views/game/detail/player.jade'
import GameDetailEvaluationTemplate from '../views/game/detail/evaluation.jade'
import GameDetailActivityTemplate from '../views/game/detail/activity.jade'
import GameDetailPluginTemplate from '../views/game/detail/plugin/index.jade'
import GameDetailSettingsTemplate from '../views/game/detail/settings/index.jade'
import GameEditGraphicalTemplate from '../views/game/edit/graphical.jade'
import GameEditFastTemplate from '../views/game/edit/fast.jade'
import GameEditJsonTemplate from '../views/game/edit/json.jade'
import GameEditPreviewTemplate from '../views/game/edit/preview/index.jade'
import InformationListTemplate from '../views/information/list.jade'
import XiumiListTemplate from '../views/xiumi/list.jade'
import PluginListTemplate from '../views/plugin/list.jade'
import PluginDetailTemplate from '../views/plugin/detail.jade'
import CommunityTeamSettingTemplate from '../views/community/team/setting.jade'

app.config(['$routeProvider', '$sceProvider', function ($routeProvider, $sceProvider) {
    $sceProvider.enabled(false);
    $routeProvider
        .when('/', {
            redirectTo: '/home'
        })
        .when('/home', {
            template: HomeTemplate,
            controller: 'HomeController'
        })
        .when('/login', {
            template: LoginTemplate,
            controller: 'LoginCtrl'
        })
        .when('/dashboard', {
            template: DashboardTemplate,
            controller: 'DashboardController'
        })
        // 账号
        .when('/account/setting', {
            redirectTo: '/account/setting/profile'
        })
        .when('/account/setting/profile', {
            template: AccountSettingProfileTemplate,
            controller: 'AccountSettingProfile'
        })
        .when('/account/setting/contact', {
            template: AccountSettingContactTemplate,
            controller: 'AccountSettingContact'
        })
        .when('/account/message', {
            template: AccountMessageContactTemplate,
            controller: 'MessageAccountController'
        })
        // 游戏
        .when('/game/new', {
            template: GameNewTemplate,
            controller: 'NewGameController'
        })
        .when('/game/multiplayer/new', {
            template: GameMultiplayerNewTemplate,
            controller: 'NewMultiplayerGameController'
        })
        .when('/game/list', {
            template: GameListTemplate,
            controller: 'GameList'
        })
        .when('/game/detail/:gameId', {
            template: GameDetailTemplate,
            controller: 'GameDetail'
        })
        .when('/game/detail/:gameId/dynamic', {
            template: GameDetailDynamicTemplate,
            controller: 'GameDetailDynamic'
        })
        .when('/game/detail/:gameId/resource', {
            template: GameDetailResourceTemplate,
            controller: 'GameDetailResource'
        })
        .when('/game/detail/:gameId/analysis', {
            template: GameDetailAnalysisTemplate,
            controller: 'GameDetailAnalysis'
        })
        .when('/game/detail/:gameId/player', {
            template: GameDetailPlayerTemplate,
            controller: 'GameDetailPlayer'
        })
        .when('/game/detail/:gameId/evaluation', {
            template: GameDetailEvaluationTemplate,
            controller: 'GameDetailEvaluation'
        })
        .when('/game/detail/:gameId/activity', {
            template: GameDetailActivityTemplate,
            controller: 'GameDetailActivity'
        })
        .when('/game/detail/:gameId/plugin', {
            template: GameDetailPluginTemplate,
            controller: 'GameDetailPlugin'
        })
        .when('/game/detail/:gameId/settings', {
            template: GameDetailSettingsTemplate,
            controller: 'GameDetailSettings'
        })
        .when('/game/edit/:gameId', {
            redirectTo: '/game/edit/:gameId/graphical'
        })
        .when('/game/edit/:gameId/graphical', {
            template: GameEditGraphicalTemplate,
            controller: 'GameEditorGraphical'
        })
        .when('/game/edit/:gameId/fast', {
            template: GameEditFastTemplate,
            controller: 'GameEditorFast'
        })
        .when('/game/edit/:gameId/json', {
            template: GameEditJsonTemplate,
            controller: 'GameEditorJson'
        })
        .when('/game/edit/:gameId/preview', {
            template: GameEditPreviewTemplate,
            controller: 'GameEditorPreview'
        })
        .when('/information/list', {
            template: InformationListTemplate,
            controller: 'InformationList'
        })
        .when('/xiumi/list', {
            template: XiumiListTemplate,
            controller: 'XiuMiList'
        })
        .when('/plugins', {
            template: PluginListTemplate,
            controller: 'PluginList'
        })
        .when('/plugin/:pluginId', {
            template: PluginDetailTemplate,
            controller: 'PluginDetail'
        })
        // 社区
        .when('/community/team/setting', {
            template: CommunityTeamSettingTemplate,
            controller: 'CommunityTeamSetting'
        })
        .otherwise({
            redirectTo: '/home'
        });
}]);


app.run(['$rootScope', '$routeParams', '$location', '$window', '$timeout', '$storage', '$ws', '$event', '$alert', '$modal', '$progress', 'ApiService', async function ($rootScope, $routeParams, $location, $window, $timeout, $storage, $ws, $event, $alert, $modal, $progress, ApiService) {
    const _ = {
        can_checkLogin: true,
    };
    let env = 'dev';
    let gameeditorurl = BASEURL + '/plugins/gameeditor.html';
    if ($window.location.hostname === 't1.itaotuo.com') env = 'test';
    if ($window.location.hostname === 'game.itaotuo.com') env = 'pro';
    if ($window.location.hostname === 'intl.game.itaotuo.com') env = 'pro';
    if ($window.location.hostname === 'author.itaotuo.com') env = 'pro';
    if (env === 'test' || env === 'pro') {
        document.domain = 'itaotuo.com';
        // gameeditorurl = 'https://resource.itaotuo.com/puzzle/plugins/gameeditor/gameeditor.html';
    }
    $rootScope.PageNow = '';
    $rootScope.__ = {
        // ng 全局变量
        ME: null,
        GAMES: null,
        IS_LOGIN: false,
        GAME_EDITOR_URL: gameeditorurl,
        UNREAD_MESSAGE_NUMBER: 0,
        ROLES: {
            creator: '创建者',
            editor: '编辑者',
            observer: '观察者',
        },

        // ng 全局UI的数据&状态
        Alert: $alert,
        Modal: $modal,
        Progress: $progress,

        // ng 全局函数
        Path: path => {
            if (path && $rootScope.__.IS_LOGIN) return $location.path(path);
            if (path) $location.path('/login').search({redirect: path});
        },
        CheckLogin: redirect => {
            if ($rootScope.__.IS_LOGIN) return true;
            if (!_.can_checkLogin) return false;
            if (redirect) $location.path('/login').search({redirect});
            return false;
        },
        GetGameImageUrl: (url) => {
            if (!url) return '';
            if (url.includes('.png') || url.includes('.jpg') || url.includes('.jpeg') || url.includes('.svg')) return url;
            return '';
        },
        GetGameImageThumbnailUrl: (url, p) => {
            if (!url) return '';
            if (url.includes('.png') || url.includes('.jpg') || url.includes('.jpeg') || url.includes('.svg')) return url + `?x-oss-process=image/resize,p_${p || 50}`;
            return '';
        },
        getGameResourceUrl: (gameId, resource = {}) => {
            return `https://resource.itaotuo.com/puzzle/games/${gameId}/${resource.name}`;
        },
        getImageStyle: (resource = {}, ImageMaxWidth) => {
            let [maxWidth, maxHeight] = [ImageMaxWidth || 220, 180];
            let [Width, Height] = [Number(resource.ImageWidth), Number(resource.ImageHeight)];
            let [width, height] = [maxWidth, maxHeight];
            let [marginX, marginY] = [0, 0];
            if (Width > Height) {
                height = Height * width / Width;
                if (height > maxHeight) {
                    width = Width * width / Width * maxHeight / height;
                    height = maxHeight;
                    marginX = (maxWidth - width) / 2;
                }
                marginY = (maxHeight - height) / 2;
            } else {
                width = Width * height / Height;
                marginX = (maxWidth - width) / 2;
            }
            return {
                width: width + 'px',
                height: height + 'px',
                marginTop: marginY + 'px',
                marginRight: marginX + 'px',
                marginBottom: marginY + 'px',
                marginLeft: marginX + 'px',
            };
        },
        getGameResourceType: (resource = {}) => {
            const fileTypeList = {
                image: ['jpg', 'jpeg', 'png'],
                gif: ['gif'],
                audio: ['mp3', 'wma', 'wav', 'amr', 'm4a'],
                video: ['mp4', 'flv', 'f4v', 'amr', 'webm', 'm4v', 'mov', '3gp', '3g2', 'rm', 'rmvb', 'wmv', 'avi', 'asf', 'mpg', 'mpeg', 'mpe', 'ts', 'div', 'dv', 'divx', 'vob', 'dat', 'mkv', 'lavf', 'cpk', 'dirac', 'ram', 'qt', 'fli', 'flc', 'mod'],
                imageAndText: ['iat'],
                dir: ['dir'],
            };
            for (let fileType in fileTypeList) {
                if (fileTypeList[fileType].includes(resource.format.toLowerCase())) return fileType;
            }
            return '';
        },
    };

    await rootInit();

    async function rootInit() {
        $rootScope.__.ME = $storage.getObject('__ME');
        let lastLoginTime = $storage.get('loginTime', Date.now());

        $event.on('login', 'login', (user, games) => {
            _.can_checkLogin = true;
            $rootScope.__.IS_LOGIN = true;
            $rootScope.__.ME = user;
            $rootScope.__.GAMES = games;
            $ws.login(user);
            if (!user.phone || !user.email) {
                // $alert('账号绑定通知: 本系统将于9月15号停止使用原账号密码登录, 请尽快使用已绑定手机号注册谜案馆APP, 使用APP扫码登录绑定账号, 如若9月15号之后还未使用APP扫码登录本系统, 可能会无法再登录此账号!', 'warning', {time: 36000000});
                $location.path('/account/setting/contact');
            } else {
                $timeout(() => {
                    $rootScope.$apply();
                    if ($location.path() === '/login') $location.path('/game/list');
                    if ($routeParams.redirect) $location.path($routeParams.redirect);
                });
            }
        });
        $event.on('logout', 'logout', () => {
            $rootScope.__.IS_LOGIN = false;
            $rootScope.__.ME = null;
            $rootScope.__.GAMES = null;
            $storage.clear();
            $event.clear();
            $ws.close();
            rootInit();
            $timeout(() => $location.path('/login'), 300);
        });

        // 自动登录
        if ($rootScope.__.ME) {
            $rootScope.__.IS_LOGIN = false;
            _.can_checkLogin = false;
            //距上次登录超过12小时重新登录
            if (Date.now() - lastLoginTime > 43200000) {
                try {
                    let user = await ApiService.loginByToken($rootScope.__.ME);
                    console.log(user.others.nickname + ' , auto login success');
                } catch (e) {
                    console.error('auto login fail !', e);
                    $alert('登录已超时, 请重新登录!', 'danger', {time: 1500});
                    $event.emit('logout');
                }
            } else {
                let user = await checkLoginStatus('autoLogin');
                if (user) {
                    user.token = $rootScope.__.ME.token;

                    let games = await ApiService.getAuthorGameList(user.userId);
                    $event.emit('login', user, games);
                    $storage.setObject('__ME', user);
                    $storage.set('loginTime', Date.now());
                }
            }
        }
    }

    $event.on('ws.login', 'ws.login', async data => {
        if (data.code === 200) {
            await checkLoginStatus('on_login');
        } else {
            $alert.error('登录验证失败, 请重新登录 ' + data.content);
            $location.path('/login');
        }
    });

    $event.on('ws.auth', 'ws.auth', async data => {
        if (data.code !== 200) {
            $alert.warning('消息服务器连接失败');
        }
        console.log('消息服务器已连接');
    });

    async function checkLoginStatus(type) {
        console.log('checkLoginStatus:', type);
        try {
            await new Promise(resolve => {
                $timeout(() => {
                    $rootScope.__.ME = $storage.getObject('__ME');
                    resolve();
                }, 100);
            })
            if (!$rootScope.__.ME) throw Error('登录超时!')
            let editorUser = await ApiService.getAuthorAccountByToken({
                userId: $rootScope.__.ME.userId,
                token: $rootScope.__.ME.token
            });
            if (!editorUser) throw Error('token错误');
            return editorUser;
        } catch (e) {
            console.error(e);
        }
        switch (type) {
            case 'autoLogin':
                $alert.error('登录超时, 请重新登录');
                $location.path('/login');
                break;
            case 'on_login':
                $alert('已在其他浏览器登录, 请重新登录', 'danger', {time: 36000000});
                break;
            default:
                $alert.error('登录超时, 请重新登录');
                $location.path('/login');
        }
    }

}]);

